import Vue from 'vue';
import Vuex from 'vuex';
import { guicheDao } from '@/service';
import ls from 'local-storage';
import { getHours, getMinutes } from 'date-fns';
// System modules

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    allPlaces: [],
    allQueues: [],
    currentPlace: null,
    lastPerson: null,
    people: [],
  },
  mutations: {
    ALL_QUEUES(state, payload){
      state.allQueues = state.allQueues.concat(payload);
    },
    SET_ALL_PLACES(state, payload) {
      state.allPlaces = payload;
    },
    SET_ALL_QUEUES(state) {
      state.allQueues = [];
      document.title = 'front-vclinic-call'
    },
    SET_CURRENT_PLACE(state, payload) {
      state.currentPlace = payload;
    },
    SET_PEOPLE(state, payload) {
      if (payload.length > 0) {
        state.people = payload.map((item) => {
          const itemDate = new Date(item.created_at);
          item.ticketHour = `${getHours(itemDate)}:${getMinutes(itemDate)}`;
          return item;
        });
      } else {
        state.people = [];
      }
    },
    SET_PUSH_PEOPLE(state, payload) {
      const personCreatedDate = new Date(payload.created_at);
      const person = {
        ...payload,
        ticketHour: `${getHours(personCreatedDate)}:${getMinutes(
          personCreatedDate
        )}`,
      };
      state.people.push(person);
    },
    SET_LAST_PERSON(state, payload) {
      state.lastPerson = payload;
    },
    SET_REMOVE_PERSON(state, item) {
      const item_key = `${item.type_key}${item.key}`;
      let peopleStaged = [];
      state.people.map((person) => {
        const item_key_filter = `${person.type_key}${person.key}`;
        if (item_key != item_key_filter) {
          peopleStaged.push(person);
        }
      });
      state.people = peopleStaged;
    },
  },
  actions: {
    deleteeQueue() {
      return new Promise((resolve, reject) => {
        guicheDao
          .deleteeQueue()
          .then((resp) => {
            resolve(resp.data);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    getAllPlaces({ commit }) {
      return new Promise((resolve, reject) => {
        guicheDao
          .getAllPlaces()
          .then((resp) => {
            commit('SET_ALL_PLACES', resp.data);
            resolve(resp.data);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    setTokensByPlace({ commit }, payload) {
      let stringUrl = '';
      payload.type === 'KEYONE'
        ? (stringUrl = stringUrl + `&place=${payload._id}&status=INPROGRESS`)
        : (stringUrl = stringUrl + '&status=START');
      return new Promise((resolve, reject) => {
        guicheDao
          .setTokensByPlace(stringUrl)
          .then((resp) => {
            ls.set('currentPlace', payload);
            commit('SET_CURRENT_PLACE', payload);
            commit('SET_PEOPLE', resp.data);
            resolve(resp.data);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    callClient({ commit }, payload) {
      let stringUrl = '';
      if (payload.type === 'KEYONE') {
        stringUrl = stringUrl + `?status=INPROGRESS`;
      } else {
        stringUrl = stringUrl + `?status=START`;
      }
      return new Promise((resolve, reject) => {
        guicheDao
          .callClient(stringUrl, payload)
          .then((resp) => {
            ls.set('lastPerson', resp.data);
            commit('SET_LAST_PERSON', resp.data);
            resolve(resp.data);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    forwardClient({ commit }, payload) {
      console.log(payload);
      return new Promise((resolve, reject) => {
        guicheDao
          .forwardClient(payload)
          .then((resp) => {
            ls.remove('lastPerson');
            commit('SET_LAST_PERSON', null);
            resolve(resp.data);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },

    patchClient(_, payload) {
      return new Promise((resolve, reject) => {
        guicheDao
          .patchClient(payload)
          .then((resp) => {
            resolve(resp.data);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    generateQueue({ commit }, payload) {
      return new Promise((resolve, reject) => {
        guicheDao
          .generateQueue(payload)
          .then((resp) => {
            ls.set('lastPerson', resp.data);
            commit('SET_LAST_PERSON', resp.data);
            resolve(resp.data);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
  },
  getters: {
    allQueues: (state) => state.allQueues,
    allPlaces: (state) => state.allPlaces,
    people: (state) => state.people,
    currentPlace: (state) => state.currentPlace,
    lastPerson: (state) => state.lastPerson,
  },
});

<template>
  <div class="vc-home-footer vc-w-100 pt-0 pb-0 pl-8 pr-8">
    <div class="white--text date">
      {{ todayString }}
    </div>

    <div class="white--text">
      Versão 0.1.0
    </div>
  </div>
</template>

<script>
export default {
  name: 'vc-home-header',
  data() {
    return {
      todayString: '',
      today: {
        day: '',
        month: '',
        year: '',
        weekDay: '',
        hour: '',
        minutes: '',
      },
      weekDays: ['Domingo', 'Segunda-feira', 'Terça-feira', 'Quarta-feira', 'Quinta-feira', 'Sexta-feira', 'Sábado'],
      months: ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'],
    };
  },
  mounted() {
    this.setCurrentDate();
    setInterval(this.setCurrentDate, 1000); // Atualiza a cada segundo
  },
  methods: {
    setCurrentDate() {
      let date = new Date();
      this.today.day = date.getDate();
      this.today.month = this.months[date.getMonth()];
      this.today.year = date.getFullYear();
      this.today.weekDay = this.weekDays[date.getDay()];
      this.today.hour = String(date.getHours()).padStart(2, '0');
      this.today.minutes = String(date.getMinutes()).padStart(2, '0');

      this.todayString = `${this.today.weekDay}, ${this.today.day} de ${this.today.month} de ${this.today.year} - ${this.today.hour}:${this.today.minutes}`;
    },
  },
};
</script>

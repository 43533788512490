<template>
  <div class="vc-w-100 vc-home-info-table">
    <v-row
      v-if="!currentPlace.forwarding"
      class="vc-w-100 ma-0 vc-flex-center mb-3"
    >
      <img height="48px" src="@/assets/img/white-logo-venx.png" alt="" />
    </v-row>
    <v-row class="vc-w-100 ma-0 mb-3">
      <v-col
        style="font-size: 25px;"
        v-if="lastPerson"
        cols="12"
        class="vc-flex-center vc-text-bold pa-0 white--text"
      >
        {{
          repeating
            ? `Último Paciente chamado: ${lastPerson.name}`
            : `Chamando Paciente novamente: ${lastPerson.name}`
        }}
      </v-col>
    </v-row>
    <v-row class="vc-w-100 vc-flex-center ma-0">
      <v-col cols="6" class="vc-flex-center pa-0">
        <v-tooltip top color="primaryDark">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              rounded
              :disabled="people.length === 0"
              color="primary"
              class="white--text"
              @click="callClient"
            >
              <div class="vc-text-button">próximo</div>
              <v-icon size="20" right color="white">
                mdi-arrow-right-thick
              </v-icon>
            </v-btn>
          </template>
          <span>
            Chamar o(a) <strong>próximo(a)</strong> pessoa da lista.
          </span>
        </v-tooltip>
      </v-col>

      <v-col cols="6" class="vc-flex-center pa-0">
        <v-tooltip top color="primaryDark">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              :disabled="!lastPerson"
              rounded
              v-on="on"
              v-bind="attrs"
              color="primary"
              class=" white--text"
              @click="repeatPerson"
            >
              <div class="vc-text-button">repetir</div>
              <v-icon size="20" right color="white">
                mdi-repeat
              </v-icon>
            </v-btn>
          </template>
          <span v-if="lastPerson">
            Chamar
            <strong>{{
              lastPerson.name
                ? `${lastPerson.name}`
                : `${lastPerson.type_key}${lastPerson.key}`
            }}</strong>
            novamente.
          </span>
        </v-tooltip>
      </v-col>
    </v-row>

    <v-row class="vc-w-100 table ma-0">
      <v-col cols="12" class="mt-5 pa-0">
        <v-row v-if="people.length > 0" class="vc-w-100 ma-0">
          <v-col cols="6" class="pa-0"
            ><div class="white--text vc-text-bold text-center">NOME</div></v-col
          >
          <v-col cols="3" class="pa-0"
            ><div class="white--text vc-text-bold text-center">HORA</div></v-col
          >
          <v-col cols="3" class="pa-0"
            ><div class="white--text vc-text-bold text-center">
              SENHA
            </div></v-col
          >
        </v-row>
      </v-col>

      <v-col style="position: relative;" cols="12" class="pa-0">
        <div class="vc-w-100 vc-people-table-content smooth-croll">
          <v-row
            v-for="(person, i) in people"
            :key="`${i}${person.name}`"
            class="vc-w-100 table ma-0 mt-1 mb-1 text-center"
            :id="`${i}${people.length}`"
          >
            <div class="vc-flex-center ml-1">
              <v-btn
                width="20"
                height="20"
                class="mx-2"
                fab
                dark
                color="primaryLight"
                @click="
                  callClientSpecific(
                    person.key,
                    person.type_key,
                    person.name ? person.name : null,
                    person._id
                  )
                "
              >
                <v-icon x-small color="primaryDark">
                  mdi-account-voice
                </v-icon>
              </v-btn>
            </div>

            <v-col
              cols="6"
              class="table-row vc-flex-center vc-text-bold primaryDark--text pa-0"
            >
              {{ person.name || '-' }}

              <!-- Botão de Editar -->
              <v-btn icon @click="editPerson(person)" class="ml-2">
                <v-icon>mdi-pencil-box</v-icon>
              </v-btn>
            </v-col>

            <v-col
              cols="2.5"
              class="table-row vc-flex-center vc-text-bold primaryDark--text ml-1 mr-1 pa-0"
            >
              {{ person.ticketHour }}
            </v-col>

            <v-col
              cols="2.5"
              class="table-row vc-flex-center vc-text-bold primaryDark--text pa-0"
            >
              {{
                person.key && person.type_key
                  ? `${person.type_key}${person.key}`
                  : '-'
              }}
            </v-col>
          </v-row>
        </div>
        <a
          id="goMoveButton"
          style="position: absolute; bottom: 10px; right: 10px;"
          class="vc-w-100 text-end"
          v-if="people.length > 6 && !goTopCheck"
          @click="goMoveTable"
        >
          <v-btn
            width="30"
            height="30"
            class="mx-2"
            fab
            dark
            color="primaryDark"
          >
            <v-icon small dark>
              mdi-arrow-down-thick
            </v-icon>
          </v-btn>
        </a>

        <a
          id="goMoveButton"
          style="position: absolute; bottom: 10px; right: 10px;"
          class="vc-w-100 text-end"
          v-else-if="people.length > 6 && goTopCheck"
          @click="goTop"
        >
          <v-btn
            width="30"
            height="30"
            class="mx-2"
            fab
            dark
            color="primaryDark"
          >
            <v-icon small dark>
              mdi-arrow-up-thick
            </v-icon>
          </v-btn>
        </a>
        <div
          v-if="people.length >= 1 && currentPlace.type === 'KEYALL'"
          class="vc-flex-center ml-1 mt-10"
        >
          <v-dialog transition="dialog-bottom-transition" max-width="600">
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="error" v-bind="attrs" v-on="on">Zerar Fila</v-btn>
            </template>
            <template v-slot:default="dialog">
              <v-card>
                <v-toolbar color="primary" dark>Zerar Fila</v-toolbar>
                <v-card-text>
                  <div class="mt-10">
                    <v-alert elevation="13" outlined prominent type="warning"
                      >Aviso esta ação irá remover as fichas de todas as salas,
                      tem certeza que quer prosseguir?</v-alert
                    >
                  </div>
                </v-card-text>
                <v-card-actions class="justify-end">
                  <v-btn color="primary" @click="deleteQueue">Confirmar</v-btn>
                  <v-btn color="error" @click="dialog.value = false"
                    >Cancelar</v-btn
                  >
                </v-card-actions>
              </v-card>
            </template>
          </v-dialog>
        </div>
        <v-row
          v-if="people.length === 0"
          class="vc-w-100 table ma-0 mt-1 mb-1 text-center"
        >
          <v-col
            style="border-radius: 3px;"
            cols="12"
            class="table-row vc-flex-center primaryDark--text pa-4"
          >
            <v-alert
              dense
              border="left"
              type="info"
              color="primary"
              class="mb-0"
            >
              Não existe nenhum cliente a <strong>espera</strong> no momento.
            </v-alert>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <v-dialog v-model="dialogEdit" max-width="600">
      <v-card>
        <v-card-title>
          <span class="text-h5">Observações de {{ personToEdit.name }}</span>
        </v-card-title>
        <v-card-text>
          <v-textarea v-model="personToEdit.observation" rows="2" auto-grow />
          <!-- Adicione outros campos conforme necessário -->
        </v-card-text>
        <v-card-actions>
          <v-btn color="secondary" @click="dialogEdit = false">Cancelar</v-btn>
          <v-btn color="primary" @click="savePerson">Salvar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'vc-home-info-table',

  props: {
    currentPlace: { type: Object, default: () => {} },
    lastPerson: { type: Object, default: () => {} },
  },
  data() {
    return {
      dialogEdit: false,
      personToEdit: {},
      repeating: false,
      goTopCheck: false,
      goMove: 0,
    };
  },
  computed: {
    ...mapGetters(['people']),
  },
  methods: {
    deleteQueue() {
      this.$store.dispatch('deleteeQueue').then(() => {
        this.$store.commit('SET_PEOPLE', []);
        this.$notify({
          group: 'foo',
          title: 'Sucesso',
          text: 'Fila Zerada com sucesso',
          type: 'success',
        });
      });
    },
    callClient() {
      this.$store.commit('SET_ALL_QUEUES');
      const person = this.people[0];
      console.log(person);
      this.repeating === false
        ? (this.repeating = true)
        : (this.repeating = false);
      this.$store.dispatch('callClient', {
        place_id: this.currentPlace._id,
        type: this.currentPlace.type,
        key: person.key,
        type_key: person.type_key,
        name: person.name ? person.name : null,
        _id: person._id,
      });
    },
    callClientSpecific(key, typeKey, name, _id) {
      this.$store.commit('SET_ALL_QUEUES');
      this.repeating === false
        ? (this.repeating = true)
        : (this.repeating = false);
      this.$store.dispatch('callClient', {
        place_id: this.currentPlace._id,
        type: this.currentPlace.type,
        key: key,
        type_key: typeKey,
        name: name,
        _id: _id,
      });
    },
    repeatPerson() {
      this.repeating = false;
      this.$socket.emit(`repeat_call`, this.lastPerson);
    },
    goMoveTable() {
      let goMoveButton = document.getElementById('goMoveButton');
      if (this.goMove + 6 > this.people.length - 1) {
        this.goMove = this.people.length - 1;
        goMoveButton.href = `#${this.goMove}${this.people.length}`;
      } else {
        this.goMove = this.goMove + 6;
        goMoveButton.href = `#${this.goMove}${this.people.length}`;
      }
      if (this.goMove === this.people.length - 4) {
        this.goTopCheck = true;
      }
    },
    goTop() {
      let goMoveButton = document.getElementById('goMoveButton');
      this.goTopCheck = false;
      goMoveButton.href = `#0${this.people.length}`;
      this.goMove = 6;
    },
    editPerson(person) {
      this.personToEdit = { ...person };
      this.dialogEdit = true;
    },
    savePerson() {
      console.log('Salvando a pessoa editada:', this.personToEdit);
      let body = {
        people_id: this.personToEdit._id,
        observation: this.personToEdit.observation,
      };
      this.$store
        .dispatch('patchClient', body)
        .then(() => {
          this.dialogEdit = false;
          this.$notify({
            group: 'foo', 
            type: 'success',
            title: 'Sucesso!',
            text: 'Observação editada com sucesso!',
          });
          setTimeout(()=>{
            location.reload();
          },500)
        })
        .catch((error) => {
          this.$notify({
            group: 'foo',
            type: 'error',
            title: 'Erro!',
            text: `Ocorreu um erro ao salvar a pessoa: ${error}`,
          });
        });
    },
  },
};
</script>

<style scoped lang="scss">
.smooth-croll {
  scroll-behavior: smooth;
}
</style>
